import React from "react"
import styles from "./index.module.scss"
import Welcome from "../components/welcome"
import Subtitle from "../components/subtitle"
import Final from "../components/final"
import SEO from "../components/seo"

export default class App extends React.Component {
  constructor() {
    super()

    this.state = {
      displayCount: -1,
    }
  }
  componentDidMount() {
    const delays = [3500, 7000]
    this.setState(
      {
        displayCount: 0,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              displayCount: 1,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  displayCount: 2,
                })
              }, delays[1])
            }
          )
        }, delays[0])
      }
    )
  }
  render() {
    const { displayCount } = this.state
    return (
      <div className={styles.container}>
        <SEO title="Deep Patel" />
        <div className={styles.wrapper}>
          {displayCount === 0 && <Welcome />}
          {displayCount === 1 && <Subtitle />}
          {displayCount === 2 && <Final />}
        </div>
      </div>
    )
  }
}
