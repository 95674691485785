import React from "react"
import styles from "./final.module.scss"
import { Link } from "gatsby"

const Wrapper = Link

export default class Final extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Wrapper to="/contact">
            <div className={styles.final}>
              <div className={styles.first}>Tell us what you need</div>
              <div className={styles.logoContainer}>
                <img
                  src={require("../assets/logo.png")}
                  alt="Deep &amp; Co. logo"
                  className={styles.logo}
                />
              </div>
              <div className={styles.last}>We'll solve it</div>
            </div>
          </Wrapper>
        </div>
      </div>
    )
  }
}
