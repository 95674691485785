import React from "react"
import Typed from "react-typed"
import styles from "./subtitle.module.scss"

export default class Subtitle extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.subtitle}>
            <p>
              <Typed
                strings={[
                  "what started as a marketing agency turned into much,^300 much^100 more",
                ]}
                typeSpeed={20}
                backSpeed={20}
              />
            </p>
          </div>
        </div>
      </div>
    )
  }
}
